import React, {useState, useEffect} from 'react'
import {Container} from 'semantic-ui-react'
import Seo from '../../../components/SEO'
import Layout from '../../../components/Layout'

import * as styles from '../../styles/pages.module.css'
import MyAccountHeader from '../../../components/Pages/MyAccount/header'

import OrderHistory from '../../../components/Pages/MyAccount/orderHistory'
const Negosyo = require('../../../../lib/negosyo')


const MyAccountOrdersCancelPage =  ({location})=> {
	const [user, setUser] = useState(undefined);
	useEffect(() => {
		Negosyo.isLoggedIn()
		.then((data)=>{
			if(data!==false) {
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					setUser(user);
				}).catch((err)=>{
				});
			}
		})

	}, [])

	return (
		<Layout location={location}>
			<Seo title={"Canceled Orders"} meta={[{name: `robots`, content: `noindex`}]} />
			<Container className={styles.container}>
				<MyAccountHeader location={location} />
				{user &&
					<OrderHistory
						title = {"Canceled Orders"}
						location={location}
						user={user}
						page={1}
					/>
				}
			</Container>
		</Layout>
	)
}

export default MyAccountOrdersCancelPage
